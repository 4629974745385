import React from "react";

import FooterClasses from "./Footer.module.scss";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <footer className={FooterClasses.footer}>
      <div className={FooterClasses.icons}>
        <a href="https://www.facebook.com/maqbuulcompany">
          <FaFacebookF size={22}/>
        </a>
        <a href="https://www.tiktok.com/@maqbuulonline">
          <FaTiktok size={22}/>
        </a>
        <a href="https://www.youtube.com/@maqbuul5662">
          <FaYoutube size={22}/>
        </a>
      </div>
      <div className={FooterClasses.text}>
        <h5>© 2025 Maqbuul™. All rights reserved</h5>
        <a href="/user_data_protection">
          <h6>User Data Protection</h6>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
