import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import removeDateImage1 from "../assets/remove_data_01.webp";
import removeDateImage2 from "../assets/remove_data_02.webp";
import removeDateImage3 from "../assets/remove_data_03.webp";

const RequestPage = () => {
  return (
    <>
      <div
        style={{
          minHeight: "280px",
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#E1E0E2",
            width: "60%",
            borderRadius: "2rem",
            height: "100%!important",
            padding: "1rem",
          }}
        >
          <div style={{}}>
            <h3>Dear Customer</h3>
          </div>
          <div
            style={{
              padding: "0rem 1.3rem",
              textAlign: "center",
            }}
          >
            <strong>
              At
              <span style={{ color: "rgb(35, 145, 254)", fontSize: "1.1rem" }}>
                {" "}
                Maqbuul™{" "}
              </span>
              ,we are dedicated to safeguarding the privacy and security of our
              users. We want to assure you that we do not collect any user data
              beyond what is necessary to verify user authenticity and confirm
              order and delivery details during the ordering process. If you
              wish to delete your account along with any associated data, please
              follow the steps outlined below. We appreciate your trust in our
              platform and remain committed to upholding the highest standards
              of privacy and data protection.
            </strong>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            minHeightheight: "60px",
            marginBottom: "1rem",
            // paddingBottom: "1rem",
            // paddingTop: "0.5rem",
            padding: "0.2rem 1rem 1rem 1rem",
          }}
        >
          <h3 style={{ fontSize: "2rem", textDecoration: "underline" }}>
            Steps for account deletion
          </h3>
        </div>
        <Container className="mb-5">
          <Row className="g-5">
            <Col xs={12} md={4}>
              <img
                src={removeDateImage1}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "3rem",
                }}
              />
            </Col>
            <Col xs={12} md={4}>
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "3rem",
                }}
                src={removeDateImage2}
                alt=""
              />
            </Col>
            <Col xs={12} md={4}>
              <img
                src={removeDateImage3}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "3rem",
                }}
              />
            </Col>
          </Row>

          <div
            style={{
              margin: "2rem",
            }}
          >
            <h3
              style={{
                textAlign: "center",
                padding: "1rem",
                textDecoration: "underline",
              }}
            >
              Manual account deletion
            </h3>
            <strong>
              For manual account deletion and the removal of all related data,
              please submit your request to
              {" "}<span
                style={{
                  display: "inline-block",
                  color: "rgb(35, 145, 254)",
                }}
              >
                {" "}
                maqbuulapp@gmail.com

              </span>{" "}
              while ensuring you are logged in with your registered account
              email.
            </strong>
            <br />
            <p style={{
              paddingTop: '1rem'
            }}>
            Note: This step (Manual account deletion) can be ignored if you have followed the steps indicated above.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default RequestPage;
